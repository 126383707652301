import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from 'nuxt/app'
import { getFirstUrlByRoles } from '~/constants/menu'
import { Auth } from '~/constants/general'
import useAuth from '~/composables/useAuth'

export default defineNuxtRouteMiddleware((to) => {
  const { token, user } = useAuth()
  const nuxtApp = useNuxtApp()
  const isAuthenticated = token.value !== null
  const isLogin = to.path === '/login'
  const publicPages = ['request-access']

  if (publicPages.includes(to.name as string)) return

  if (!isAuthenticated && isLogin) return
  if (!isAuthenticated && !isLogin) return navigateTo({ path: '/login' }, { replace: true })

  if (user.value !== null && user.value[Auth.auth0RolesProperty]?.roles.length === 0) {
    showError({ statusCode: 401 })
  }

  if (isAuthenticated && isLogin) {
    const routesArray = to.path.split('/')
    const isAdminMode = routesArray.includes('admin')
    const url = getFirstUrlByRoles(isAdminMode, nuxtApp.$authorization, to.path)
    return navigateTo({ path: url }, { replace: true })
  }
})
