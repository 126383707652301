<template>
  <div>
    <div class="flex items-center justify-end w-full px-3 pt-3">
      <MyAccount :show-account-details-link="false" />
    </div>
    <error-message
      :message="errorMessageContent.message"
      :title="errorMessageContent.title"
      :show-return-to-home-page="errorMessageContent.showReturnToHomePage"
    />
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'dashboard',
})

const props = defineProps<{
  error: {
    statusCode: number
    message: string
  }
}>()

const errorMessages = {
  401: {
    title: 'Unauthorized',
    message: `User doesn't have any role assigned,`,
    showReturnToHomePage: false,
  },
  403: {
    title: 'Forbidden',
    message: 'Unfortunately, you do not have permissions to access this page,',
    showReturnToHomePage: true,
  },
  404: {
    title: 'Error 404',
    message:
      'Sorry, page not found, we were unable to find the page (Error 404) you’re looking for,',
    showReturnToHomePage: true,
  },
  500: {
    title: 'Error 500',
    message:
      'There has been an Internal Server Error (Error 500). Please refresh, if the problem persists,',
    showReturnToHomePage: true,
  },
  600: {
    title: 'Unexpected error',
    message: 'An error occurred, please reload the page. If the error persists',
    showReturnToHomePage: true,
  },
}

const errorString = computed(() => {
  let errorString = props.error.statusCode ? `${props.error.statusCode}: ` : ''
  errorString += props.error.message ? props.error.message : 'Unknown error'
  return errorString
})

const errorMessageContent = computed(() => {
  const error = errorMessages[props.error.statusCode]

  if (!error) {
    return {
      title: 'error',
      message: errorString.value,
      showReturnToHomePage: true,
    }
  }

  return errorMessages[props.error.statusCode]
})
</script>
