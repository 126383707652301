import { storeToRefs } from 'pinia'
import { useNuxtApp, useRoute } from 'nuxt/app'
import { getFirstUrlByRoles, filterMenuByRoles } from '~/constants/menu'
import { useGeneralStore } from '~/stores/general'

const useMenu = () => {
  const nuxtApp = useNuxtApp()
  const route = useRoute()

  const generalStore = useGeneralStore()
  const { isAdminMode } = storeToRefs(generalStore)

  const filterMenu = (typeMenu) => {
    return filterMenuByRoles(typeMenu, nuxtApp.$authorization)
  }

  const getFirstUrl = () => {
    return getFirstUrlByRoles(isAdminMode.value, nuxtApp.$authorization, route.path)
  }

  return { isAdminMode, filterMenu, getFirstUrl }
}

export default useMenu
