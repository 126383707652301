import axios, { AxiosInstance } from 'axios'
import { useRuntimeConfig } from 'nuxt/app'
import useAuth from '~/composables/useAuth'
import { useCustomToast } from '~/composables/useCustomToast'

const useAxios = (): AxiosInstance => {
  const runtimeConfig = useRuntimeConfig()
  const API_URL = runtimeConfig.public.API_URL
  const { refreshToken, logout, isValidToken } = useAuth()
  const { toastErrors } = useCustomToast()

  const instance = axios.create({
    baseURL: API_URL,
  })

  const retryRefreshToken = async () => {
    try {
      const newToken = await refreshToken()
      instance.defaults.headers.common.Authorization = `Bearer ${newToken}`
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      await logout()
    }
  }

  instance.interceptors.request.use(async (config) => {
    if (!isValidToken()) await retryRefreshToken()

    const token = window.localStorage.getItem('token') || ''
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  instance.interceptors.response.use(
    (response) => response.data,
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          await logout()
          return
        }
        toastErrors(error)
        return Promise.reject(error)
      }

      if (error.request) {
        const isOnline = navigator.onLine
        if (!isOnline) {
          const offlineError = new Error(
            "Sorry, but we're unable to process your request as there is no internet connection. Please ensure you are connected to a network before trying again.",
          )
          return Promise.reject(offlineError)
        }
      }

      const unexpectedError = new Error(
        "An unexpected error has occurred. If this issue persists, please don't hesitate to reach out to our support team for assistance.",
      )

      toastErrors(unexpectedError)
      return Promise.reject(unexpectedError)
    },
  )

  return instance
}

export default useAxios
