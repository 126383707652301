import { useToast } from 'vue-toastification'
import ToastSuccessIcon from '~/components/ui/felixds/ToastSuccessIcon.vue'

export const useCustomToast = () => {
  const runtimeConfig = useRuntimeConfig()
  const { NODE_ENV } = runtimeConfig.public
  const toast = useToast()

  const toastErrors = (err) => {
    if (NODE_ENV === 'development') {
      if (Array.isArray(err)) {
        err.forEach((error) => toast.error(error.toString()))
      } else {
        toast.error(err.toString())
      }
    }
  }

  const toastSuccess = (message) => {
    toast.success(message, {
      icon: ToastSuccessIcon,
    })
  }

  return {
    toastErrors,
    toastSuccess,
  }
}
