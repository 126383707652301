import useAxios from '~/composables/http/useAxios'
import { BATTERY_CELLS } from '~/constants/urls'
import formatString from '~/utils/formatString'

export const useBatteryCells = () => {
  const axios = useAxios()

  const getOverviewHeaders = () => axios.get(BATTERY_CELLS.GLOBAL_HEADER)

  const getGigaFactories = () => axios.get(BATTERY_CELLS.GLOBAL_GIGAFACTORIES_CAPACITIES)

  const getOverview = () => axios.get(BATTERY_CELLS.GLOBAL_OVERVIEW)

  const getPlantDetail = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.PLANT_DATA_SOURCE, plantId)
    return axios.get(uri)
  }

  const getPlantDetailHeader = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.PLANT_HEADER, plantId)
    return axios.get(uri)
  }

  const getCompanyDetail = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.COMPANY_DATA_SOURCE, plantId)
    return axios.get(uri)
  }

  const getCompanyDetailHeader = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.COMPANY_HEADER, plantId)
    return axios.get(uri)
  }

  const getCountryDetail = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.COUNTRY_DATA_SOURCE, plantId)
    return axios.get(uri)
  }

  const getCountryDetailHeader = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.COUNTRY_HEADER, plantId)
    return axios.get(uri)
  }

  const getRegionDetail = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.REGION_DATA_SOURCE, plantId)
    return axios.get(uri)
  }

  const getRegionDetailHeader = (plantId: string) => {
    const uri = formatString(BATTERY_CELLS.REGION_HEADER, plantId)
    return axios.get(uri)
  }

  const getBatteryCellsInfo = () => axios.get(BATTERY_CELLS.INFO)

  return {
    getOverviewHeaders,
    getGigaFactories,
    getOverview,
    getPlantDetail,
    getPlantDetailHeader,
    getCompanyDetail,
    getCompanyDetailHeader,
    getCountryDetail,
    getCountryDetailHeader,
    getRegionDetail,
    getRegionDetailHeader,
    getBatteryCellsInfo,
  }
}
