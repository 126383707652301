import { defineNuxtPlugin } from 'nuxt/app'
import useAxios from '~/composables/http/useAxios'
import { useBatteryCells } from '~/composables/http/useBatteryCells'

export default defineNuxtPlugin(() => {
  const axios = useAxios()
  const batteryCells = useBatteryCells()

  return { provide: { axios, batteryCells } }
})
