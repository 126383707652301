import intersection from 'lodash/intersection'
import { defineNuxtPlugin } from 'nuxt/app'
import { Auth } from '~/constants/general'
import { ROLES } from '~/constants/roles'
import { AdminMenuData } from '~/constants/menu'

export default defineNuxtPlugin((nuxtApp) => {
  const auth = nuxtApp.$auth

  const hasUser = () => auth.user.value

  const isAdmin = () => {
    if (!hasUser()) return false
    return auth.user.value[Auth.auth0RolesProperty].roles.includes('Admin')
  }

  const hasAccess = (roles = []) => {
    if (isAdmin()) return true

    if (!hasUser()) return false

    return Boolean(intersection(roles, auth.user.value[Auth.auth0RolesProperty].roles).length)
  }

  const hasLithiumRole = () => {
    return hasAccess([ROLES.LITHIUM_READ])
  }

  const hasAdminPermission = () => {
    const adminRoles = Object.keys(AdminMenuData)
      .map((key) => AdminMenuData[key].roles)
      .flat()
    return hasAccess(adminRoles)
  }

  const hasSingleRole = (role) => {
    if (!hasUser()) return false
    return (
      auth.user.value[Auth.auth0RolesProperty].roles.length === 1 &&
      auth.user.value[Auth.auth0RolesProperty].roles[0] === role
    )
  }

  const onlyDocsRole = () => hasSingleRole(ROLES.DOCS_READ)

  const hasOnlyLithiumReadRole = () => {
    if (!hasUser()) return false

    const readRoleRegexp = /^([^:]+):read$/

    const allReadRoles = auth.user.value[Auth.auth0RolesProperty].roles.filter((role) =>
      readRoleRegexp.test(role),
    )

    return allReadRoles.length === 1 && allReadRoles[0] === ROLES.LITHIUM_READ
  }

  const hasDownloadPermission = () => hasAccess([ROLES.DOWNLOAD])

  const authorization = {
    isAdmin,
    hasDownloadPermission,
    onlyDocsRole,
    hasAccess,
    hasLithiumRole,
    hasAdminPermission,
    hasOnlyLithiumReadRole,
  }
  return {
    provide: {
      authorization,
    },
  }
})
