export enum BATTERY_CELLS {
  GLOBAL_HEADER = 'v1/battery-cells/overview/headers',
  GLOBAL_OVERVIEW = 'v1/battery-cells/overview/data',
  GLOBAL_GIGAFACTORIES_CAPACITIES = 'v1/battery-cells/giga-factories-capacities',
  INFO = 'v1/info/battery-cells',
  PLANT_DATA_SOURCE = 'v1/battery-cells/plants/{0}/data',
  PLANT_HEADER = 'v1/battery-cells/plants/{0}/header',
  COMPANY_DATA_SOURCE = 'v1/battery-cells/companies/{0}/data',
  COMPANY_HEADER = 'v1/battery-cells/companies/{0}/header',
  COUNTRY_DATA_SOURCE = 'v1/battery-cells/countries/{0}/data',
  COUNTRY_HEADER = 'v1/battery-cells/countries/{0}/header',
  REGION_DATA_SOURCE = 'v1/battery-cells/regions/{0}/data',
  REGION_HEADER = 'v1/battery-cells/regions/{0}/header',
  PRICES = 'v1/prices/battery-cells',
}

export enum CATHODES {
  GLOBAL_MARKET_PIPELINE = 'v1/electrodes/cathodes/overview/global-market-pipeline',
  GLOBAL_MARKET_TECHNOLOGY = 'v1/electrodes/cathodes/overview/global-market-technology-current-year',
  PRODUCTION = 'v1/electrodes/cathodes/overview/production',
  YOY_TECHNOLOGY_CHANGE = 'v1/electrodes/cathodes/overview/yoy-technology-change',
  CAPACITY_RAMP_UP_BY_TIER = 'v1/electrodes/cathodes/overview/capacity-ramp-up-by-tier',
  MARKET_BALANCE_GEOGRAPHY = 'v1/electrodes/cathodes/market-balance-geography',
  MARKET_BALANCE_CHEMISTRY = 'v1/electrodes/cathodes/market-balance-chemistry',
  END_MARKET_DEMAND = 'v1/electrodes/cathodes/end-market-demand',
  SUPPLY_AND_DEMAND = 'v1/electrodes/cathodes/supply-and-demand',
  MARKET_BALANCE_STATUS = 'v1/electrodes/cathodes/market-balance-status',
  MARKET_BALANCE_TIER = 'v1/electrodes/cathodes/market-balance-tier',
  GLOBAL_CAPACITY = 'v1/electrodes/cathodes/master/global/capacity',
  GLOBAL_HEADER = 'v1/electrodes/cathodes/master/global/header',
  GLOBAL_TECHNOLOGY = 'v1/electrodes/cathodes/master/global/technology',
  PRICES = 'v1/prices/cathodes',
  PRICES_HEADER = 'v1/prices/cathodes/headers',
  PRICES_RANGE = 'v1/prices/cathodes/range',
  PRICE_INDEX = 'v1/price-index/cathodes',
  INFO = 'v1/info/cathodes',
  FACILITY_DATA_SOURCE = 'v1/electrodes/cathodes/supply/facility/{0}/datasource',
  FACILITY_HEADER = 'v1/electrodes/cathodes/supply/facility/{0}/header',
  COMPANY_DATA_SOURCE = 'v1/electrodes/cathodes/supply/company/{0}/datasource',
  COMPANY_HEADER = 'v1/electrodes/cathodes/supply/company/{0}/header',
  LOCATION_DATA_SOURCE = 'v1/electrodes/cathodes/supply/region/{0}/datasource',
  LOCATION_HEADER = 'v1/electrodes/cathodes/supply/region/{0}/header',
}

export enum ANODES {
  GLOBAL_MARKET_PIPELINE = 'v1/electrodes/anodes/overview/global-market-pipeline',
  GLOBAL_MARKET_TECHNOLOGY = 'v1/electrodes/anodes/overview/global-market-technology-current-year',
  PRODUCTION = 'v1/electrodes/anodes/overview/production',
  YOY_TECHNOLOGY_CHANGE = 'v1/electrodes/anodes/overview/yoy-technology-change',
  CAPACITY_RAMP_UP_BY_TIER = 'v1/electrodes/anodes/overview/capacity-ramp-up-by-tier',
  GLOBAL_CAPACITY = 'v1/electrodes/anodes/master/global/capacity',
  GLOBAL_HEADER = 'v1/electrodes/anodes/master/global/header',
  GLOBAL_TECHNOLOGY = 'v1/electrodes/anodes/master/global/technology',
  PRICES = 'v1/prices/anodes',
  PRICES_HEADERS = 'v1/prices/anodes/headers',
  PRICES_RANGE = 'v1/prices/anodes/range',
  PRICE_INDEX = 'v1/price-index/anodes',
  INFO = 'v1/info/anodes',
  END_MARKET_DEMAND = 'v1/electrodes/anodes/end-market-demand',
  SUPPLY_AND_DEMAND = 'v1/electrodes/anodes/supply-and-demand',
  MARKET_BALANCE_STATUS = 'v1/electrodes/anodes/market-balance-status',
  MARKET_BALANCE_TIER = 'v1/electrodes/anodes/market-balance-tier',
  MARKET_BALANCE_CHEMISTRY = 'v1/electrodes/anodes/market-balance-chemistry',
  MARKET_BALANCE_GEOGRAPHY = 'v1/electrodes/anodes/market-balance-geography',
  ACTIVE_MATERIAL_DEMAND_CURRENT_YEAR = 'v1/electrodes/anodes/active-material-demand-current-year',
  FACILITY_DATA_SOURCE = 'v1/electrodes/anodes/supply/facility/{0}/datasource',
  FACILITY_HEADER = 'v1/electrodes/anodes/supply/facility/{0}/header',
  COMPANY_DATA_SOURCE = 'v1/electrodes/anodes/supply/company/{0}/datasource',
  COMPANY_HEADER = 'v1/electrodes/anodes/supply/company/{0}/header',
  LOCATION_DATA_SOURCE = 'v1/electrodes/anodes/supply/region/{0}/datasource',
  LOCATION_HEADER = 'v1/electrodes/anodes/supply/region/{0}/header',
}

export enum LITHIUM {
  END_MARTKET = 'v1/lithium/demand/short-term/end-market',
  BATTERY_PRODUCTION_CURRENT_YEAR = 'v1/lithium/demand/short-term/battery-production-current-year',
  CELL_DEMAND_BY_GEOGRAPHY = 'v1/lithium/demand/short-term/cell-demand-geography',
  EV_GLOBAL_SALES = 'v1/lithium/demand/short-term/ev-global-sales',
  MARKET = 'v1/lithium/demand/long-term/market',
  EV_SALES = 'v1/lithium/demand/long-term/ev-sales',
  MONTHLY_EV_SALES = 'v1/lithium/demand/ev-sales',
  CHEMISTRY = 'v1/lithium/demand/long-term/chemistry',
  BATTERY = '/v1/lithium/demand/long-term/battery',
  GEOGRAPHY_DEMAND = '/v1/lithium/demand/regions',
  TECHNOLOGY_CHANGE = '/v1/lithium/demand/cathode-market-share',
  INFO = 'v1/info/lithium',
  MINED_INFO = 'v1/info/lithium/supply/mined',
  CHEMICAL_INFO = 'v1/info/lithium/supply/chemical',
  GLOBAL_HEADER = 'v1/lithium/global/headers',
  PRICES_LITHIUM_CHANGES = 'v2/prices/lithium/changes',
  MINED_SUPPLY_AND_DEMAND = 'v1/lithium/overview/supply-and-demand/mined',
  MINED_BALANCE = 'v1/lithium/overview/balance/mined',
  CHEMICAL_SUPPLY_AND_DEMAND = 'v1/lithium/overview/supply-and-demand/chemical',
  CHEMICAL_SUPPLY_AND_DEMAND_DETAILED = 'v1/lithium/overview/supply-and-demand/chemical/detailed',
  CHEMICAL_BALANCE = 'v1/lithium/overview/balance/chemical',
  PRICES = 'v1/prices/lithium',
  PRICES_DETAIL = 'v1/lithium/prices/{0}',
  PRICES_FOREIGN = 'v1/prices/lithium/foreign',
  PRICE_INDEX = 'v1/price-index/lithium',
  PRICES_RANGE = 'v1/prices/lithium/range',
  PRICES_HEADERS = '/v1/lithium/global/headers',
  PRICES_DOWNLOAD_METHODOLOGY = 'v2/prices/methodology/lithium-methodology',
  PRICES_DOWNLOAD_FORECASTS = 'v1/forecast/file-download/price-forecast',
  PRICES_SUMMARY_V2 = '/v2/prices/lithium/summary',
  PRICES_V2 = '/v2/prices/lithium',
  PRICES_RANGE_V2 = '/v2/prices/lithium/range',
  PRICE_INDEX_V2 = 'v2/prices/lithium/index',
  EQUITY_DATA = 'v2/equity-data/lithium',
  MINED_OVERVIEW = '/v1/lithium/supply/mined/overview',
  MINED_MINE_OVERVIEW = 'v1/lithium/supply/mined/mines/overview/charts/annual-lithium-production',
  MINED_COMPANIES_OVERVIEW = 'v1/lithium/supply/mined/companies/overview/charts/top-producers',
  MINED_COUNTRIES_OVERVIEW = 'v1/lithium/supply/mined/countries/overview/charts/top-producers',
  MINED_REGIONS_OVERVIEW = 'v1/lithium/supply/mined/regions/overview/charts/top-producers',
  MINED_ORE_TYPE_OVERVIEW_TOP_PRODUCERS = 'v1/lithium/supply/mined/oretypes/overview/charts/top-producers',
  MINED_MINE_DETAIL = 'v1/lithium/supply/raw-material/mine/{0}',
  MINED_MINE_DETAIL_HEADER = 'v1/lithium/supply/raw-material/mine/{0}/headers',
  MINED_REGION_DETAIL = 'v1/lithium/supply/raw-material/region/{0}/group/{1}',
  MINED_REGION_DETAIL_HEADER = 'v1/lithium/supply/raw-material/region/{0}/headers',
  MINED_COUNTRY_DETAIL = 'v1/lithium/supply/raw-material/country/{0}/group/{1}',
  MINED_COUNTRY_DETAIL_HEADER = 'v1/lithium/supply/raw-material/country/{0}/headers',
  MINED_COMPANIES_DETAIL = 'v1/lithium/supply/raw-material/companies/{0}/group/{1}',
  MINED_COMPANIES_DETAIL_HEADER = 'v1/lithium/supply/raw-material/companies/{0}/headers',
  MINED_ORE_TYPE_DETAIL_HEADER = 'v1/lithium/supply/mined/oretypes/{0}/headers',
  MINED_ORE_TYPE_DETAIL = 'v1/lithium/supply/mined/oretypes/{0}/group-by/{1}',
  CHEMICAL_PLANTS_OVERVIEW = 'v1/lithium/supply/chemical/plants/overview/charts/annual-lithium-production',
  CHEMICAL_OVERVIEW = 'v1/lithium/supply/chemical/overview/fact-box',
  CHEMICAL_COMPANIES_OVERVIEW = 'v1/lithium/supply/chemical/companies/overview/charts/top-producers',
  CHEMICAL_COUNTRIES_OVERVIEW = 'v1/lithium/supply/chemical/countries/overview/charts/top-producers',
  CHEMICAL_FEEDSTOCKS_OVERVIEW = 'v1/lithium/supply/chemical/feedstocks/overview/charts/top-producers',
  CHEMICAL_PRODUCTS_OVERVIEW = 'v1/lithium/supply/chemical/products/overview/charts/top-producers',
  CHEMICAL_REGIONS_OVERVIEW = 'v1/lithium/supply/chemical/regions/overview/charts/top-producers',
  CHEMICAL_PLANT_DETAIL = 'v1/lithium/supply/chemical/plant/{0}',
  CHEMICAL_PLANT_DETAIL_HEADER = 'v1/lithium/supply/chemical/plant/{0}/headers',
  CHEMICAL_COMPANIES_DETAIL = 'v1/lithium/supply/chemical/companies/{0}/group/{1}',
  CHEMICAL_COMPANIES_DETAIL_HEADER = 'v1/lithium/supply/chemical/companies/{0}/headers',
  CHEMICAL_COUNTRY_DETAIL = 'v1/lithium/supply/chemical/country/{0}/group/{1}',
  CHEMICAL_COUNTRY_DETAIL_HEADER = 'v1/lithium/supply/chemical/country/{0}/headers',
  CHEMICAL_REGION_DETAIL = 'v1/lithium/supply/chemical/region/{0}/group/{1}',
  CHEMICAL_REGION_DETAIL_HEADER = 'v1/lithium/supply/chemical/region/{0}/headers',
  CHEMICAL_FEEDSTOCK_DETAIL = 'v1/lithium/supply/chemical/feedstocks/{0}/group-by/{1}',
  CHEMICAL_FEEDSTOCK_DETAIL_HEADER = 'v1/lithium/supply/chemical/feedstocks/{0}/headers',
  CHEMICAL_PRODUCT_DETAIL = 'v1/lithium/supply/chemical/products/{0}/group-by/{1}',
  CHEMICAL_PRODUCT_DETAIL_HEADER = 'v1/lithium/supply/chemical/products/{0}/headers',
  ALL_MINES = 'v1/lithium/mines',
  ALL_PLANTS = '/v1/lithium/chemical/plants',
  MINE_STATUS = '/v1/lithium/mine-status',
  ALL_COUNTRIES = '/v1/lithium/countries',
  PRICE_FORECASTS = '/v1/forecast/lithium/price-forecast',
}

export enum NATURAL_GRAPHITE {
  MARKET_BALANCE = 'v1/natural-graphite/overview/market-balance',
  INFO = 'v1/info/natural-graphite',
  PRICES = 'v1/prices/natural-graphite',
  PRICE_INDEX = 'v1/price-index/natural-graphite',
  PRICES_RANGE = 'v1/prices/natural-graphite/range',
  PRICES_HEADERS = 'v1/prices/natural-graphite/headers',
  DEMAND_BROKEN_DOWN_BY_END_USE = 'v1/natural-graphite/demand/short-term/demand-broken-down-by-end-use',
  STEEL_PRODUCTION = 'v1/steel/demand/short-term/production-last-twelve-months',
  PRODUCTION_BY_COUNTRY = 'v1/electrodes/anodes/short-term/production-by-country',
  MARKET = 'v1/natural-graphite/demand/long-term/demand-by-market',
  BATTERY_DEMAND = 'v1/natural-graphite/demand/long-term/demand-by-battery-usage',
  MARKET_SHARE = 'v1/natural-graphite/demand/long-term/anode-market-share',
  EV_SALES = 'v1/natural-graphite/demand/long-term/ev-sales',
  MINES_DETAIL = 'v1/natural-graphite/supply/mines/{0}/datasource',
  MINES_DETAIL_HEADER = 'v1/natural-graphite/supply/mines/{0}/headers',
  COMPANIES_DETAIL = 'v1/natural-graphite/supply/companies/{0}/datasource',
  COMPANIES_DETAIL_HEADER = 'v1/natural-graphite/supply/companies/{0}/headers',
  COUNTRIES_DETAIL = 'v1/natural-graphite/supply/countries/{0}/datasource',
  COUNTRIES_DETAIL_HEADER = 'v1/natural-graphite/supply/countries/{0}/headers',
  REGIONS_DETAIL = 'v1/natural-graphite/supply/regions/{0}/datasource',
  REGIONS_DETAIL_HEADER = 'v1/natural-graphite/supply/regions/{0}/headers',
}

enum LITHIUM_FORECAST_COSTS_BASE_URL {
  UPLOAD_AND_FIND = '/v1/forecast/lithium/etl/cost-model',
  SAVE_AND_DELETE = '/v1/forecast/lithium/etl/cost-model/{0}',
}

export enum ADMIN {
  USER_MANAGEMENT = '/v1/user-management/crm-users',
  SAVE_USER_MANAGEMENT = '/v1/user-management/terminal-users',
}

export enum ADMIN_HISTORY {
  LIST_HISTORY = '/v1/history/etl/{0}/list',
  DOWNLOAD = '/v1/history/etl/file/{0}',
  DELETE_HISTORY = '/v1/history/etl/{0}',

  ANODES_FORECAST_UPLOAD_FILE = '/v1/etl-electrodes/forecast/anodes',
  ANODES_FORECAST_SAVE_DATA = '/v1/etl-electrodes/forecast/anodes/{0}',
  ANODES_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/anodes-index',
  ANODES_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/anodes-index/{0}',
  ANODES_PRICES_UPLOAD_FILE = '/v2/prices/etl/anodes',
  ANODES_PRICES_SAVE_DATA = '/v2/prices/etl/anodes/{0}',
  ANODES_UPLOAD_FILE = '/v1/etl-electrodes/master/anodes',
  ANODES_SAVE_DATA = '/v1/etl-electrodes/master/anodes/{0}',
  BATTERY_CELLS_UPLOAD_FILE = '/v1/etl-battery-cells/master',
  BATTERY_CELLS_SAVE_DATA = '/v1/etl-battery-cells/master/{0}',
  BATTERY_CELLS_PRICES_UPLOAD_FILE = '/v2/prices/etl/lithium-ion-battery',
  BATTERY_CELLS_PRICES_SAVE_DATA = '/v2/prices/etl/lithium-ion-battery/{0}',
  BATTERY_CELLS_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/lithium-ion-battery-index',
  BATTERY_CELLS_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/lithium-ion-battery-index/{0}',
  CATHODES_UPLOAD_FILE = '/v1/etl-electrodes/master/cathodes',
  CATHODES_SAVE_DATA = '/v1/etl-electrodes/master/cathodes/{0}',
  CATHODES_PRICES_UPLOAD_FILE = '/v2/prices/etl/cathodes',
  CATHODES_PRICES_SAVE_DATA = '/v2/prices/etl/cathodes/{0}',
  CATHODES_FORECAST_UPLOAD_FILE = '/v1/etl-electrodes/forecast/cathodes',
  CATHODES_FORECAST_SAVE_DATA = '/v1/etl-electrodes/forecast/cathodes/{0}',
  CATHODES_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/cathodes-index',
  CATHODES_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/cathodes-index/{0}',
  COBALT_PRICES_UPLOAD_FILE = '/v2/prices/etl/cobalt',
  COBALT_PRICES_SAVE_DATA = '/v2/prices/etl/cobalt/{0}',
  COBALT_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/cobalt-index',
  COBALT_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/cobalt-index/{0}',
  LITHIUM_EV_SALES_UPLOAD_FILE = '/v1/etl-lithium-csv/demand-vehicle-sales',
  LITHIUM_EV_SALES_SAVE_DATA = '/v1/etl-lithium-csv/demand-vehicle-sales/{0}',
  LITHIUM_FORECAST_UPLOAD_FILE = '/v2/etl-lithium-xlsx/forecast',
  LITHIUM_FORECAST_SAVE_DATA = '/v2/etl-lithium-xlsx/forecast/{0}',
  LITHIUM_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/lithium-index',
  LITHIUM_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/lithium-index/{0}',
  LITHIUM_PRICES_UPLOAD_FILE = '/v2/prices/etl/lithium',
  LITHIUM_PRICES_SAVE_DATA = '/v2/prices/etl/lithium/{0}',
  MANGANESE_PRICES_UPLOAD_FILE = '/v2/prices/etl/manganese',
  MANGANESE_PRICES_SAVE_DATA = '/v2/prices/etl/manganese/{0}',
  ELECTROLYTE_PRICES_UPLOAD_FILE = '/v2/prices/etl/electrolyte',
  ELECTROLYTE_PRICES_SAVE_DATA = '/v2/prices/etl/electrolyte/{0}',
  NATURAL_GRAPHITE_FORECAST_UPLOAD_FILE = '/v1/natural-graphite/etl/forecast',
  NATURAL_GRAPHITE_FORECAST_SAVE_DATA = '/v1/natural-graphite/etl/forecast/{0}',
  NATURAL_GRAPHITE_PRICES_UPLOAD_FILE = '/v2/prices/etl/natural-graphite',
  NATURAL_GRAPHITE_PRICES_SAVE_DATA = '/v2/prices/etl/natural-graphite/{0}',
  NATURAL_GRAPHITE_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/natural-graphite-index',
  NATURAL_GRAPHITE_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/natural-graphite-index/{0}',
  NICKEL_PRICES_UPLOAD_FILE = '/v2/prices/etl/nickel',
  NICKEL_PRICES_SAVE_DATA = '/v2/prices/etl/nickel/{0}',
  NICKEL_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/nickel-index',
  NICKEL_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/nickel-index/{0}',
  STEEL_PRODUCTION_UPLOAD_FILE = '/v1/steel/etl/production',
  STEEL_PRODUCTION_SAVE_DATA = '/v1/steel/etl/production/{0}',
  SYNTHETIC_GRAPHITE_PRICES_UPLOAD_FILE = '/v2/prices/etl/synthetic-graphite',
  SYNTHETIC_GRAPHITE_PRICES_SAVE_DATA = '/v2/prices/etl/synthetic-graphite/{0}',
  SYNTHETIC_GRAPHITE_PRICE_INDEX_UPLOAD_FILE = '/v2/prices/etl/synthetic-graphite-index',
  SYNTHETIC_GRAPHITE_PRICE_INDEX_SAVE_DATA = '/v2/prices/etl/synthetic-graphite-index/{0}',
  RARE_EARTHS_PRICES_SAVE_DATA = '/v2/prices/etl/rare-earths/{0}',
  RARE_EARTHS_PRICES_UPLOAD_FILE = '/v2/prices/etl/rare-earths',
  LITHIUM_FORECAST_COSTS_SAVE_DATA = '/v1/forecast/lithium/etl/cost-model/{0}',
  LITHIUM_FORECAST_COSTS_UPLOAD_FILE = '/v1/forecast/lithium/etl/cost-model',
  LITHIUM_PRICES_FORECAST_SAVE_DATA = '/v1/forecast/lithium/etl/price-forecast/{0}',
  LITHIUM_PRICES_FORECAST_UPLOAD_FILE = '/v1/forecast/lithium/etl/price-forecast',
  BLACK_MASS_PRICES_UPLOAD_FILE = '/v2/prices/etl/black-mass',
  BLACK_MASS_PRICES_SAVE_DATA = '/v2/prices/etl/black-mass/{0}',
  BLACK_MASS_PRICES_INDEX_UPLOAD_FILE = '/v2/prices/etl/black-mass-index',
  BLACK_MASS_PRICES_INDEX_SAVE_DATA = '/v2/prices/etl/black-mass-index/{0}',
  BATTERY_CELLS_RAW_MATERIAL_INDEX_UPLOAD_FILE = '/v2/prices/etl/lithium-ion-battery-raw-material-index',
  BATTERY_CELLS_RAW_MATERIAL_INDEX_SAVE_DATA = '/v2/prices/etl/lithium-ion-battery-raw-material-index/{0}',
}

export const ADMIN_HISTORY_CUSTOM_DOMAINS = Object.freeze({
  NATURAL_GRAPHITE_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/natural-graphite-index',
  NATURAL_GRAPHITE_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/natural-graphite-index/{0}/download',
  NATURAL_GRAPHITE_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/natural-graphite-index/{0}',
  CATHODES_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/cathodes-index',
  CATHODES_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/cathodes-index/{0}/download',
  CATHODES_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/cathodes-index/{0}',
  CATHODES_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/cathodes',
  CATHODES_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/cathodes/{0}/download',
  CATHODES_PRICES_DELETE_DATA: '/v2/prices/etl/cathodes/{0}',
  LITHIUM_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/lithium-index',
  LITHIUM_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/lithium/{0}/download',
  LITHIUM_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/lithium-index/{0}',
  LITHIUM_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/lithium',
  LITHIUM_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/lithium/{0}/download',
  LITHIUM_PRICES_DELETE_DATA: '/v2/prices/etl/lithium/{0}',
  RARE_EARTHS_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/rare-earths',
  RARE_EARTHS_PRICES_DELETE_DATA: '/v2/prices/etl/rare-earths/{0}',
  RARE_EARTHS_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/rare-earths/{0}/download',
  LITHIUM_FORECAST_COSTS_FIND_ALL_HISTORY: LITHIUM_FORECAST_COSTS_BASE_URL.UPLOAD_AND_FIND,
  LITHIUM_FORECAST_COSTS_DELETE_DATA: LITHIUM_FORECAST_COSTS_BASE_URL.SAVE_AND_DELETE,
  LITHIUM_FORECAST_COSTS_DOWNLOAD_DATA: '/v1/forecast/lithium/etl/cost-model/{0}/download',
  LITHIUM_PRICES_FORECAST_FIND_ALL_HISTORY: '/v1/forecast/lithium/etl/price-forecast',
  LITHIUM_PRICES_FORECAST_DELETE_DATA: '/v1/forecast/lithium/etl/price-forecast/{0}',
  LITHIUM_PRICES_FORECAST_DOWNLOAD_DATA: '/v1/forecast/lithium/etl/price-forecast/{0}/download',
  MANGANESE_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/manganese',
  MANGANESE_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/manganese/{0}/download',
  MANGANESE_PRICES_DELETE_DATA: '/v2/prices/etl/manganese/{0}',
  ELECTROLYTE_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/electrolyte',
  ELECTROLYTE_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/electrolyte/{0}/download',
  ELECTROLYTE_PRICES_DELETE_DATA: '/v2/prices/etl/electrolyte/{0}',
  COBALT_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/cobalt',
  COBALT_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/cobalt/{0}/download',
  COBALT_PRICES_DELETE_DATA: '/v2/prices/etl/cobalt/{0}',
  COBALT_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/cobalt-index',
  COBALT_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/cobalt-index/{0}/download',
  COBALT_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/cobalt-index/{0}',
  BATTERY_CELLS_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/lithium-ion-battery',
  BATTERY_CELLS_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/lithium-ion-battery/{0}/download',
  BATTERY_CELLS_PRICES_DELETE_DATA: '/v2/prices/etl/lithium-ion-battery/{0}',
  BATTERY_CELLS_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/lithium-ion-battery-index',
  BATTERY_CELLS_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/lithium-ion-battery-index/{0}/download',
  BATTERY_CELLS_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/lithium-ion-battery-index/{0}',
  NATURAL_GRAPHITE_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/natural-graphite',
  NATURAL_GRAPHITE_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/natural-graphite/{0}/download',
  NATURAL_GRAPHITE_PRICES_DELETE_DATA: '/v2/prices/etl/natural-graphite/{0}',
  ANODES_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/anodes',
  ANODES_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/anodes/{0}/download',
  ANODES_PRICES_DELETE_DATA: '/v2/prices/etl/anodes/{0}',
  ANODES_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/anodes-index',
  ANODES_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/anodes-index/{0}/download',
  ANODES_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/anodes-index/{0}',
  NICKEL_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/nickel',
  NICKEL_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/nickel/{0}/download',
  NICKEL_PRICES_DELETE_DATA: '/v2/prices/etl/nickel/{0}',
  NICKEL_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/nickel-index',
  NICKEL_PRICE_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/nickel-index/{0}/download',
  NICKEL_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/nickel-index/{0}',
  SYNTHETIC_GRAPHITE_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/synthetic-graphite',
  SYNTHETIC_GRAPHITE_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/synthetic-graphite/{0}/download',
  SYNTHETIC_GRAPHITE_PRICES_DELETE_DATA: '/v2/prices/etl/synthetic-graphite/{0}',
  SYNTHETIC_GRAPHITE_PRICE_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/synthetic-graphite-index',
  SYNTHETIC_GRAPHITE_PRICE_INDEX_DOWNLOAD_DATA:
    '/v2/prices/etl/synthetic-graphite-index/{0}/download',
  SYNTHETIC_GRAPHITE_PRICE_INDEX_DELETE_DATA: '/v2/prices/etl/synthetic-graphite-index/{0}',
  BLACK_MASS_PRICES_FIND_ALL_HISTORY: '/v2/prices/etl/black-mass',
  BLACK_MASS_PRICES_DOWNLOAD_DATA: '/v2/prices/etl/black-mass/{0}/download',
  BLACK_MASS_PRICES_DELETE_DATA: '/v2/prices/etl/black-mass/{0}',
  BLACK_MASS_PRICES_INDEX_FIND_ALL_HISTORY: '/v2/prices/etl/black-mass-index',
  BLACK_MASS_PRICES_INDEX_DOWNLOAD_DATA: '/v2/prices/etl/black-mass-index/{0}/download',
  BLACK_MASS_PRICES_INDEX_DELETE_DATA: '/v2/prices/etl/black-mass-index/{0}',
  BATTERY_CELLS_RAW_MATERIAL_INDEX_FIND_ALL_HISTORY:
    '/v2/prices/etl/lithium-ion-battery-raw-material-index',
  BATTERY_CELLS_RAW_MATERIAL_INDEX_DOWNLOAD_DATA:
    '/v2/prices/etl/lithium-ion-battery-raw-material-index/{0}/download',
  BATTERY_CELLS_RAW_MATERIAL_INDEX_DELETE_DATA:
    '/v2/prices/etl/lithium-ion-battery-raw-material-index/{0}',
})

export enum COSTS_MODEL {
  LITHIUM = '/v1/forecast/lithium/costs',
}

export enum ACCOUNT {
  CHANGE_PASSWORD = 'v1/admin/profile/change-password',
}

export enum PRICES {
  GET_ARTICLES = '/v2/prices/{0}/articles',
}
