import { createAuth0Client } from '@auth0/auth0-spa-js'
import jwtDecode from 'jwt-decode'
import { useRuntimeConfig } from 'nuxt/app'
import type { User } from '~/constants/general'

let AUTH_INSTANCE = null

const useAuth = () => {
  const runtimeConfig = useRuntimeConfig()
  const { AUTH0_CLIENT_ID, AUTH0_AUDIENCE, AUTH0_DOMAIN } = runtimeConfig.public

  const getAuth = async () => {
    if (!AUTH_INSTANCE) {
      const redirectUri = `${window.location.origin}/login`
      AUTH_INSTANCE = await createAuth0Client({
        useRefreshTokens: true,
        domain: AUTH0_DOMAIN,
        clientId: AUTH0_CLIENT_ID,
        cacheLocation: 'localstorage',
        authorizationParams: {
          redirect_uri: redirectUri,
          audience: AUTH0_AUDIENCE,
          scope: 'openid profile email',
          max_age: 1800,
        },
      })
    }

    return Object.freeze(AUTH_INSTANCE)
  }

  const setUser = (value) => {
    window.localStorage.setItem('user', JSON.stringify(value))
  }

  const setToken = (value) => {
    window.localStorage.setItem('token', value)
  }

  const user = computed((): User => JSON.parse(window.localStorage.getItem('user')))

  const token = computed(() => window.localStorage.getItem('token'))

  const isValidToken = () => {
    const token = window.localStorage.getItem('token')

    if (!token) return false

    const claims = jwtDecode<{ exp: number }>(token)
    return Date.now() < claims.exp * 1000
  }

  const logout = async () => {
    const auth = await getAuth()
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('token')
    auth.logout({ logoutParams: { returnTo: window.location.origin } }).then()
  }

  const refreshToken = async () => {
    const auth = await getAuth()
    const token = await auth.getTokenSilently()
    setToken(token)
    return token
  }

  return {
    user,
    token,
    getAuth,
    setUser,
    setToken,
    logout,
    refreshToken,
    isValidToken,
  }
}

export default useAuth
