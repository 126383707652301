const ChartId = {
  ProductionCapacity: 'production_capacity',
  CathodeTypeCapacityPerc: 'cathode_type_perc',
  RawMaterialDemand: 'raw_material_demand',
}

const BatteryPackSizeAverage = 55000 / 1e9

const ReportType = {
  Plant: {
    route: 'plants',
  },
  Company: {
    route: 'companies',
  },
  Country: {
    route: 'countries',
  },
  Region: {
    route: 'regions',
  },
} as any

ReportType.Company.productionCapacitySegmentation = ReportType.Plant
ReportType.Region.productionCapacitySegmentation = ReportType.Country

const Auth = {
  auth0RolesProperty: 'http://benchmarkpro.io/user',
}

export type User = {
  // @ts-ignore
  [Auth.auth0RolesProperty]: {
    roles: string[]
  }
}

const CommonLabels = Object.freeze({
  TONNES_LCE: 'Tonnes, LCE',
})

const Units = Object.freeze({
  TONNES: 'Tonnes',
  GWH: 'GWh',
  MWH: 'MWh',
  TPA: 'tpa',
  MINE: 'Mine',
  MINES: 'Mines',
  FACILITY: 'Facility',
  FACILITIES: 'Facilities',
  PLANT: 'Plant',
  PLANTS: 'Plants',
  PERCENTAGE: '%',
  DOLLAR: '$',
  VEHICLES: 'vehicles',
  UNITS_SOLD: 'Units Sold',
  USD_PER_TONNE: 'USD/tonne',
  UNITS: 'units',
  MILLIONS: 'Millions',
  MILLIONS_UNITS: 'million units',
  LCE: 'LCE',
})

const currentYear = new Date().getFullYear()
const statusNames = Object.freeze({
  CurrentProduction: `${currentYear} Production`,
  Existing: 'Existing Production Capacity',
  UnderConstruction: 'Capacity Under Construction',
  Planing: 'Capacity In Planning',
})

const DateFormats = Object.freeze({
  mmhh_dd_MMMM_yyyy: 'HH:mm, dd MMMM yyyy',
  dd_MMMM_yyyy: 'dd MMMM yyyy',
  MMM_dd_yyyy: 'MMM dd, yyyy',
  MMMM_yyyy: 'MMMM yyyy',
  yyyy_MM_dd: 'yyyy-MM-dd',
  dd_MM_yyyy: 'dd/MM/yyyy',
  MMM_yyyy: 'MMM yyyy',
  MMM_yy: 'MMM yy',
  yyyy: 'yyyy',
  MMM_d_yyyy: 'MMM. d, yyyy',
  HH_mm_zzz_dd_MMMM_yyyy: 'HH:mm (zzz) dd MMMM yyyy',
  dd_MMM_YYYY: 'dd MMM yyyy',
  dd_MMM_YY: 'dd MMM yy',
  QUARTERLY: 'QQQ yyyy',
})

export type FactboxSetting = {
  label: string
  units: string
  htmlValue: boolean
  fixedDecimalPlace: boolean
  numberWithCommas: boolean
  toDecimalPlaces: boolean
  concatValue: string
  formatArray?: boolean
  dataTestAttribute: string
  validateHasOther?: boolean
  concatLabel?: boolean
  labelClass?: string
  valueClass?: string
}

const supplyFactboxesCommonSettings = {
  labelClass: 'text-white text-left',
  valueClass: 'text-p-yellow-500 text-left',
}

const extendSupplyFactboxesCommonSettings = (valueClasses: string) => ({
  labelClass: 'text-white text-left',
  valueClass: `text-p-yellow-500 text-left ${valueClasses}`,
})

const FactboxTexts = Object.freeze({
  priceIndex: {
    label: 'Today’s Price Index<br>&nbsp;',
    units: Units.PERCENTAGE,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: false,
    toDecimalPlaces: true,
    concatValue: null,
    dataTestAttribute: 'todays-price-index',
  },
  priceRanges: {
    label: 'Price Range $/T',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'price-range',
  },
  annualProductionGWH: {
    label: `Annual Production<br>Equates to ${Units.GWH}`,
    units: Units.GWH,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: null,
    dataTestAttribute: `annual-production-equates-to-${Units.GWH}`,
  },
  annualProduction: {
    label: 'Annual Production<br>&nbsp;',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    dataTestAttribute: 'annual-production',
  },
  presentAnnualCapacity: {
    label: 'Present Annual Capacity',
    units: Units.GWH,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} {1}`,
    dataTestAttribute: 'present-annual-capacity',
  },
  pipelineCapacity: {
    label: `Pipeline Capacity to ${new Date().getFullYear() + 7}`,
    units: Units.GWH,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.GWH}`,
    dataTestAttribute: `pipeline-capacity-to-${new Date().getFullYear() + 7}`,
  },
  globalAnnualCapacity: {
    label: 'Global Annual Capacity',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    dataTestAttribute: 'global-annual-capacity',
  },
  activeGigafactories: {
    label: 'Active',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'active',
  },
  inactiveGigafactories: {
    label: 'Planning / In development',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'planning-in-development',
  },
  selectedGigafactories: {
    label: 'Total',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'total',
  },
  totalGWh: {
    label: null,
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'total-gwh',
  },
  format: {
    label: 'Cell Format',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'cell-format',
  },
  launchYear: {
    label: 'Launch Year',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'launch-year',
  },
  tier: {
    label: 'Tier',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'tier',
  },
  mainChemistry: {
    label: null,
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'main-chemestry',
  },
  carbonateGWA: {
    label: 'Global Weighted Average $/T',
    units: Units.PERCENTAGE,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `${Units.DOLLAR}{0}`,
    dataTestAttribute: 'carbonate-global-weighted-average',
  },
  priceCarbonateGWA: {
    label: ['Global Weighted Average', 'Carbonate $/T'],
    fixedDecimalPlace: true,
    concatValue: `${Units.DOLLAR}{0} ({1}${Units.PERCENTAGE})`,
    dataTestAttribute: 'factbox-carbonate-global-weighted-average',
  },
  premiumPrice: {
    label: ['EXW China Hydroxide vs', 'Carbonate Delta (premium)'],
    fixedDecimalPlace: true,
    concatValue: `${Units.DOLLAR}{0}`,
    dataTestAttribute: 'factbox-premium-price-label',
  },
  lithiumCarbonateGWA: {
    label: 'Global Weighted Average<br>Carbonate $/T',
    labelClass: 'text-p-yellow-500',
    units: Units.PERCENTAGE,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `${Units.DOLLAR}{0}`,
    dataTestAttribute: 'lithium-carbonate-global-weighted-average',
  },
  annualProductionMined: {
    label: '{0} Mined<br>Production',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    concatLabel: true,
    dataTestAttribute: 'annual-mined-production',
  },
  annualProductionChemical: {
    label: '{0} Chemical<br>Production',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    concatLabel: true,
    dataTestAttribute: 'annual-chemical-production',
  },
  annualDemandMined: {
    label: '{0} Demand',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    concatLabel: true,
    dataTestAttribute: 'annual-mined-demand',
  },
  equateProductionGWHMined: {
    label: 'Annual Mined Production<br>Equates to GWh',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'annual-mined-production-equates-to-gwh',
  },
  productionTpa: {
    label: 'Present Annual Production',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    dataTestAttribute: 'present-annual-production',
  },
  oreTypes: {
    label: 'Ore Type',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'factbox-ore-type',
    validateHasOther: true,
  },
  status: {
    label: 'Status',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'status-label',
  },
  precursorMaterial: {
    label: 'Precursor Material',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'precursor-material',
  },
  presentAnnualProduction: {
    label: 'Present Annual Production',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'present-annual-production',
  },
  feedStock: {
    label: 'Feedstock',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'feed-stock-label',
    validateHasOther: true,
  },
  product: {
    label: 'Product',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'factbox-product-label',
  },
  products: {
    label: 'Product',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'factbox-products',
  },
  priceIndexChangeMonthly: {
    label: 'Flake Graphite Price<br>Index Change (Monthly)',
    units: Units.PERCENTAGE,
    htmlValue: true,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'flake-graphite-price-inde-change-monthly',
  },
  annualProductionMinedGWH: {
    label: 'Annual Mined Production<br>Equates to GWh',
    units: Units.GWH,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: null,
    dataTestAttribute: 'annual-mined-production-equates-to-gwh',
  },
  customYearAnnualProduction: {
    label: '{0} Production',
    concatLabel: true,
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    dataTestAttribute: '2040-annual-production',
  },

  operatingMines: {
    label: 'Operating Mines',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'operating-mines',
  },
  plannedProjects: {
    label: 'Planned Projects',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    dataTestAttribute: 'planned-projects-label',
  },
  currentProduction: {
    label: '{0} Production',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatLabel: true,
    concatValue: `{0} ${Units.TPA}`,
    dataTestAttribute: 'current-production',
  },
  currentDemand: {
    label: '{0} Demand',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    concatLabel: true,
    dataTestAttribute: 'current-demand-label',
  },
  nameplateCapacity: {
    label: 'Nameplate Capacity',
    units: Units.TPA,
    htmlValue: false,
    fixedDecimalPlace: true,
    numberWithCommas: true,
    toDecimalPlaces: true,
    concatValue: `{0} ${Units.TPA}`,
    dataTestAttribute: 'nameplate-capacity',
  },

  operator: {
    label: 'Operator',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'factbox-operator-label',
  },
  ownership: {
    label: 'Ownership',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'ownership-label',
  },
  companyHQ: {
    label: 'Company HQ',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'company-hq-label',
  },
  benchmarkSustainabilityIndex: {
    label: 'Benchmark Sustainability Index',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'benchmark-sustainability-index-label',
  },
  totalOperatingMines: {
    label: 'Operating Mines',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'total-operating-mines',
  },
  operatingPlants: {
    label: 'Operating Plants',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'operating-plants-label',
  },
  totalPlannedProjects: {
    label: 'Planned Projects',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'total-planned-projects',
  },
  regionsProduction: {
    label: 'Regions Production',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'factbox-regions-production-label',
  },
  regionsOfProduction: {
    label: 'Regions of Production',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'regions-of-production',
  },
  totalLithiumDemand: {
    label: 'Total Lithium Demand<br>{0} (t, LCE)',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'total-lithium-demand',
  },
  totalMinedLithium: {
    label: 'Total Mined Lithium<br>Production {0} (t, LCE)',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'total-mined-lithium',
  },
  totalChemicalLithium: {
    label: 'Total Chemical Lithium<br>Production {0} (t, LCE)',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'total-chemical-lithium',
  },
  lithiumMarketValue: {
    label: 'Lithium Market<br>Value ($)',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: true,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: false,
    dataTestAttribute: 'lithium-market-value',
  },
  marketPosition: {
    label: 'Market Position',
    units: null,
    htmlValue: false,
    fixedDecimalPlace: false,
    numberWithCommas: false,
    toDecimalPlaces: false,
    concatValue: null,
    formatArray: true,
    dataTestAttribute: 'market-position-label',
  },
})

const BreadcrumbsTexts = Object.freeze({
  prevItems: null,
  regions: { multipleLabel: 'Multiple regions', path: 'regions' },
  location: { multipleLabel: 'Multiple locations', path: 'locations' },
  countries: { multipleLabel: 'Multiple countries', path: 'countries' },
  companies: { multipleLabel: 'Multiple companies', path: 'companies' },
})

const formatRegionsBreadCrumbs = (data) => {
  return [
    {
      label: data.label,
      id: data.id,
      url: null,
    },
  ]
}

const getTabType = (tab) => {
  if (tab === 'operating country') return 'country'
  return tab.replaceAll(' ', '-')
}

const decimalsObj = {
  [Units.TONNES]: 0,
  [Units.GWH]: 1,
  [Units.MWH]: 1,
  [Units.TPA]: 0,
  [Units.PERCENTAGE]: 1,
  [Units.DOLLAR]: 0,
  [Units.VEHICLES]: 0,
  [Units.UNITS_SOLD]: 0,
  [Units.USD_PER_TONNE]: 0,
  [Units.MILLIONS]: 2,
  [Units.MILLIONS_UNITS]: 2,
  EQUITY_DATA: 2,
}

const CATEGORIES_CHART = Object.freeze([
  {
    name: 'Lithium Hydroxide',
    alias: 'LiOH',
    unit: 'USD/metric tonne (LCE)',
    category: 'lithium',
    format: '#,##0',
  },
  {
    name: 'Lithium Carbonate',
    alias: 'Li2CO3',
    unit: 'USD/metric tonne (LCE)',
    category: 'lithium',
    format: '#,##0',
  },
  {
    name: 'Spodumene',
    alias: 'Li2O',
    unit: 'USD/metric tonne',
    category: 'lithium',
    format: '#,##0',
  },
  {
    name: '',
    alias: '',
    unit: 'USD/kWh',
    category: 'battery-cell',
    format: '#,##0.00',
  },
])

const getPropertyOrCategoryByProperty = (
  valueSearch,
  propertySearch = 'name',
  propertyResponse = null,
) => {
  const category = CATEGORIES_CHART.find((item) => item[propertySearch] === valueSearch)
  if (propertyResponse) {
    return category ? category[propertyResponse] : valueSearch
  }
  return category
}

const validateIsNegativeZero = (value: string | number): string | number => {
  return value.toString() === '-0.0' ? '0.0' : value
}

const dataPerTypesAnodesCathodes = Object.freeze({
  anodes: {
    dataUrl: 'getAnodeReport',
    prevItems: ['Anodes', 'Supply'],
  },
  cathodes: {
    dataUrl: 'getCathodeReport',
    prevItems: ['Cathodes', 'Supply'],
  },
})

const returnObjectWhenIsOverview = () => {
  return {
    isOverview: true,
    headers: null,
  }
}

const customColumns = (weightedProduction: string) => {
  return [
    { order: 3, key: 'production', label: weightedProduction, width: 300, alignment: 'right' },
    { order: 4, key: 'hqs', label: 'HQ', isCompact: true, alignment: 'center' },
    {
      order: 5,
      key: 'operatingCountries',
      label: 'Operating countries',
      width: 240,
      alignment: 'left',
    },
  ]
}

const getPercentageChange = (value: number, prevValue: number): number => {
  return ((value - prevValue) / prevValue) * 100
}

const ANALYSIS_LABEL = 'Analysis'

enum ValueType {
  WEIGHTED = 'weighted',
  UNWEIGHTED = 'unweighted',
}

const INITIAL_VALUE_TYPE = ValueType.WEIGHTED

export {
  Auth,
  BatteryPackSizeAverage,
  ChartId,
  ReportType,
  Units,
  statusNames,
  DateFormats,
  FactboxTexts,
  supplyFactboxesCommonSettings,
  extendSupplyFactboxesCommonSettings,
  BreadcrumbsTexts,
  formatRegionsBreadCrumbs,
  decimalsObj,
  getTabType,
  getPropertyOrCategoryByProperty,
  validateIsNegativeZero,
  dataPerTypesAnodesCathodes,
  returnObjectWhenIsOverview,
  customColumns,
  getPercentageChange,
  ANALYSIS_LABEL,
  CommonLabels,
  ValueType,
  INITIAL_VALUE_TYPE,
}

export const PROJECT_NAME = 'Benchmark Terminal'
